// 验证姓名

import {
    Dialog
} from "vant";

// #! 统一添加前缀 exp, 避免名称与页面内冲突
const expRules = {}
//名字验证
expRules.expCheckName = (name) => /^[\u4e00-\u9fa5●]{2,}$/ig.test(name.trim())

//手机号码验证
expRules.expCheckPhone = (phoneNumber) => /^1[3-9]\d{9}$/ig.test(phoneNumber.trim())


//身份证号码验证
expRules.expCheckIDCard = (idcode) => {
    // 加权因子
    var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    // 校验码
    var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

    var code = idcode + "";
    var last = idcode[17]; //最后一位

    var seventeen = code.substring(0, 17);

    // ISO 7064:1983.MOD 11-2
    // 判断最后一位校验码是否正确
    var arr = seventeen.split("");
    var len = arr.length;
    var num = 0;
    for (var i = 0; i < len; i++) {
        num = num + arr[i] * weight_factor[i];
    }

    // 获取余数
    var resisue = num % 11;
    var last_no = check_code[resisue];

    // 格式的正则
    // 正则思路
    /*
    第一位不可能是0
    第二位到第六位可以是0-9
    第七位到第十位是年份，所以七八位为19或者20
    十一位和十二位是月份，这两位是01-12之间的数值
    十三位和十四位是日期，是从01-31之间的数值
    十五，十六，十七都是数字0-9
    十八位可能是数字0-9，也可能是X
    */
    var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

    // 判断格式是否正确
    var format = idcard_patter.test(idcode);

    // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
    return last === last_no && format ? true : false;
}

//邮箱验证
expRules.expCheckEmail = (email) => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/ig.test(email.trim())

//银行卡号验证
expRules.expCheckBankNum = (back) => {
    var regExp = /^\d+$|^\d+[.]?\d+$/
    // var regExp = /^([1-9]{1})(\d{15}|\d{18})$/;
    return regExp.test(back.trim())
}

/**
 * 工号规则
 * @param {*} val 
 */
expRules.expCheckJobNum = (val) => /^\d{1,7}$/ig.test(val)

//地址验证
/**
 *
 * @param {string} addr 地址
 */
expRules.expCheckAddress = (addr) => {
    if (addr.search(/['组号室队院弄']/ig) == -1) return false
    // 沙雕正则范围匹配{}, 这里面不能有任何空格
    return /[\u4e00-\u9fa5●\w\~\#\&\(\)（）\_\-\,，\.\/]{4,150}/ig.test(addr)

}

//出生证明验证(1位字母+9位数字)
expRules.expCheckBorn = (born) => {
    var regExp = /^[A-Z]{1}\d{9}$/ig;
    return regExp.test(born)
}
//自定义验证
expRules.expCheckCustom = (custom) => {
    var regExp = /[^a-zA-Z0-9\u4e00-\u9fa5\u3002\uff0c\uff1a\uff08\uff09\uff1f\u201c\u201d\u3001\uff01,/.!:()?_""—-]/g;
    return regExp.test(custom)
}







// function getAge(date) {
//     var birthday = date.getFullYear()
//     var nowYear = new Date().getFullYear()
//     return nowYear - birthday
// }

function getAge(strAge) {

    var birArr = []
    if (isDate(strAge)) {
        birArr = [strAge.getFullYear(), strAge.getMonth() + 1, strAge.getDate()];
    } else {
        birArr = strAge.split("/");
    }
    var birYear = birArr[0];
    var birMonth = birArr[1];
    var birDay = birArr[2];

    d = new Date();
    var nowYear = d.getFullYear();
    var nowMonth = d.getMonth() + 1; //记得加1
    var nowDay = d.getDate();
    var returnAge;

    if (birArr == null) {
        return false
    };
    var d = new Date(birYear, birMonth - 1, birDay);
    if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
        if (nowYear == birYear) {
            returnAge = 0; //
        } else {
            var ageDiff = nowYear - birYear; //
            if (ageDiff > 0) {
                if (nowMonth == birMonth) {
                    var dayDiff = nowDay - birDay; //
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    var monthDiff = nowMonth - birMonth; //
                    if (monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                }
            } else {
                return "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
            }
        }
        return returnAge;
    } else {
        return ("输入的日期格式错误！");
    }
}

function getYmd(date) {
    const d = new Date(date)
    const y = d.getFullYear()
    const m = String(d.getMonth() + 1).padStart(2, '0')
    const day = String(d.getDate()).padStart(2, '0')
    return `${y}/${m}/${day}`
}



const isDate = (date) => Object.prototype.toString.apply(date) === '[object Date]'

function getQueryVariable(variable) {
    var query = decodeURIComponent(window.location.search.substring(1));
    console.log(query, 'query')
    // var vars = query.split("&");
    var vars = query.split("&");
    console.log(vars, 'vars')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");

        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}


/**
 * 对象所有属性值是否都不为空（属性值为string类型
 * @param {Object} obj 
 * @param {String} excludeAttr 不需要校验的属性
 * 
 */
const objVal = (obj, excludeAttr = '') => {
    let extraVal = [];
    for (let [k, v] of Object.entries(obj)) {
        if (k == excludeAttr) continue
        extraVal.push(v);
    }
    let hasEmpty = extraVal.findIndex((item) => item == "")

    return hasEmpty == -1 ? true : false
}

// 通过身份证获取生日
const getBirthDay = (idCode) => {

    let getYmd = idCode.length == 15 ? `19${idCode.substr(5, 6)}` : idCode.substr(6, 8);

    let ymdDate = `${getYmd.substr(0, 4)}/${getYmd.substr(4, 2)}/${getYmd.substr(-2)}`;

    return ymdDate;

}

// 获取本地缓存
const getStorage = (key) => {
    return JSON.parse(localStorage.getItem(key))
}

const clearStorage = () => {
    return localStorage.clear()
}

const clearItemStorage = (key) => {
    return localStorage.removeItem(key)
}

// 设置本地缓存
const setStorage = (key, obj) => {
    let newDATA = obj
    let oldDATA = getStorage(key)
    if (oldDATA != null) {
        newDATA = Object.assign({}, oldDATA, obj)
    }
    localStorage.setItem(key, JSON.stringify(newDATA))
}



/**
 * 特定用法之 - 清空输入 
 */
const removeInputVal = () => {
    const data = {
        insured_object_select: 1, // 为谁投保
        bank: "",
        cardNum: "",
        name: "", //姓名
        idType: "身份证",
        idCode: "", //证件号
        sex: "-",
        idValidity: 1, // 证件有效期
        idValidityEndTime: "请选择您证件截止日期",
        area: "请选择省/市/区", // 地址
        _area: [],
        street: "", // 街道
        village: "", // 村
        address: "", //地址
        phone: "", //手机号
        mail: "", //电子邮箱
        work: "请选择职业",
        _workCode: "",
        income: "", // 年收入
        _age: "",
        _birthday: "",

        getWay: "1", // 领取方式v
        resSources: "自己", // 资源来源人
        _resSourcesOther: false,
        jobNum: "", // 工号

        familyInsuredMember: 0, // 为谁投保
        familyRelation: "-",
        familyName: "", //姓名
        familySex: "请选择性别", // 家人性别
        familyBirthday: "", //出生日期
        familyIdType: "身份证",
        familyIdCode: "", //证件号码
        familyIdValidity: 1,
        familyIdValidityEndTime: "请选择您证件截止日期",
        familyArea: "请选择省/市/区", // 地址
        _familyArea: [],
        familyStreet: "", // 街道
        familyVillage: "", // 村
        familyAddress: "",
        familyWork: "请选择职业",
        _familyAge: "",

    }
    // const _extra = {
    //     idcard_front: "",
    //     idcard_front_ftp: "",
    //     idcard_back: "",
    //     idcard_back_ftp: "",
    //     face_id: "",
    //     insured_idcard_front: "", // 被保人
    //     insured_idcard_front_ftp: "",
    //     insured_idcard_back: "",
    //     insured_idcard_back_ftp: "",
    //     insured_face_id: "",
    // }
    // const extraId = Object.assign({}, data, {
    //     _extra: _extra
    // })
    localStorage.setItem('insureInfo', JSON.stringify(data))
}



const helperTip = (p = {
    msg: ''
}) => {
    Dialog.alert({
        message: p.msg
    }).then(() => { });
}


export {
    expRules,
    getAge,
    getYmd,
    isDate,
    getQueryVariable,
    objVal,
    getBirthDay,
    getStorage,
    setStorage,
    removeInputVal,
    clearStorage,
    clearItemStorage,
    helperTip,
};

