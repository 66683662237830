import { getWx } from '@/config/request';
import wx from 'weixin-js-sdk';
const ShareImpl = function (option) {
    // option是分享的配置内容*/
    // const url = window.location.href.split("#")[0]+window.location.href.split("#")[1].substr(1);
    // const url = window.location.href;
    // console.log(url, 'wx-url')
    // window.console.log(url, JSON.stringify(option)); 

    getWx({
        url: location.href
    }).then((res) => {
        console.log(res, 'wx------res');
        let data1 = res.data;

        // let data2 = JSON.parse(res.data);
        // console.log(data2)
        // console.log(res,'wx-data');
        // console.log(res.jsApiList,'wx-data');
        // wx.config({
        //     debug: true,
        //     appId: res.appId,
        //     timestamp: res.timestamp,
        //     nonceStr: res.nonceStr,
        //     signature: res.signature,
        //     jsApiList: [
        //         'updateAppMessageShareData',
        //         'updateTimelineShareData'
        //     ]
        // });

        data1.beta = true
        console.log(data1, 'data111111')

        wx.config(data1)
        wx.ready(function () {
            wx.onMenuShareTimeline({
                title: option.shareTitle,
                link: option.shareUrl,
                imgUrl: option.shareImg,
                success: function () {
                    console.log('我是旧的111111')
                },
                complete: function () {
                    console.log(22222222222)
                }
            });
            wx.onMenuShareAppMessage({
                title: option.shareTitle, // 分享标题
                desc: option.shareDesc, // 分享描述
                link: option.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: option.shareImg, // 分享图标
                type: '', // 分享类型,music、video或link，不填默认为link
                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                success: function () {
                    // 用户点击了分享后执行的回调函数
                    console.log('我是旧的2222222')
                },
                complete: function () {
                    console.log(22222222222)
                }
            });
            wx.updateAppMessageShareData({
                title: option.shareTitle,
                desc: option.shareDesc,
                link: option.shareUrl,
                imgUrl: option.shareImg,
                complete: function () {
                    console.log(22222222222)
                }

            });
            wx.updateTimelineShareData({
                title: option.shareTitle,
                desc: option.shareDesc,
                link: option.shareUrl,
                imgUrl: option.shareImg,
                complete: function () {
                    console.log(22222222222)
                }
            });

        })
    }).catch(error => {
        window.console.log(error);
    });

    /*}*/
}

export default ShareImpl