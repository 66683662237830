<template lang="pug">
#app
    transition(name="fade")
        keep-alive(include="home,search")
            router-view
    Footer(v-if="show")
</template>
<script>
import Footer from "@/components/footer.vue";
export default {
    name: "App",
    data() {
        return {
            show: true,
        };
    },
    components: {
        Footer,
    },
    // created() {
    //     if(location.href.includes('shareUrl')) {
    //          location.href = location.origin + '/#' + util.getParamFromUrl().shareUrl;
    //         // 将https://my-web.com/?from=singlemessage&isappinstalled=0&shareUrl=/item?id=2
    //         // 拼接成https://my-web.com/#/item?id=2

    //         // 注意location.href并不是立刻执行，会继续往下执行代码，因此要return掉
    //          return;
    //     }
    // },
    watch: {
        $route(to, from) {
            if (to.path == "/" || to.path == "/user") {
                this.show = true;
            } else {
                this.show = false;
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
#app
    background #f5f5f5

.fade-enter-active, .fade-leave-active
    transition opacity 0.2s

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    opacity 0
</style>
