/** ************************************************ 数据处理 Start ************************************************ **/

/**
 * 数据深拷贝
 * @param obj
 * @returns {any}
 */
export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

/**
 * 加载更多数据
 */
export function setMoreData(_this, data, list_type, callBack, size = 8) {
    let upData = data
    _this.loading = false
    if (upData.length == 0) {
        _this.finished = true

        if (_this.page == 1) {
            _this[list_type] = []
        }
        return
    } else {
        if (_this.page == 1) {
            _this[list_type] = [].concat(upData)
            if (_this[list_type].length >= size) {
                _this.finished = false
            } else {
                _this.finished = true
            }
        } else {
            if (upData.length >= size) {
                _this.finished = false
            } else {
                _this.finished = true
            }
            _this[list_type] = _this[list_type].concat(upData)
        }
    }
    typeof callBack == 'function' && callBack()
}

export default {
    deepCopy,
    setMoreData
}
