<template lang="pug">
.footer
    router-link.linkbox(
        v-for="(item, index) in menu",
        :key="index",
        :to="item.link"
    )
        .menuitem(:class="item.class") {{ item.name }}

    //- router-link.linkbox(to='/user' :class="{active:active==1}")
    //-     .foo-right 我的
</template>




 

<script>
export default {
    props: {},
    data() {
        return {
            menu: [
                {
                    name: "选车",
                    class: "foo-left",
                    link: "/",
                },
                {
                    name: "我的",
                    class: "foo-right",
                    link: "/user",
                },
            ],
        };
    },
};
</script>
<style lang="stylus" scoped>
.footer
    height 60px
    display flex
    position fixed
    left 0
    right 0
    bottom 0
    width 100%
    background-color #fff

    .linkbox
        display flex
        flex 1
        justify-content center
        flex-direction row
        align-items center
        color #333333

        .foo-left
            padding-top 25px
            display flex
            flex 1
            justify-content center
            background url('../assets/mine/foot2.png') top center / 22px auto no-repeat

        .foo-right
            padding-top 25px
            display flex
            flex 1
            justify-content center
            background url('../assets/mine/foot1.png') top center / 22px auto no-repeat

    // .active
    // color #3e71f6
    // .foo-left
    // background url('../assets/mine/foot1-1.png') top center/ 22px auto no-repeat
    // .foo-right
    // background url('../assets/mine/foot1-2.png') top center/ 22px auto no-repeat
    .router-link-exact-active
        color #3e71f6

        .foo-left
            background url('../assets/mine/foot1-1.png') top center / 22px auto no-repeat

        .foo-right
            background url('../assets/mine/foot1-2.png') top center / 22px auto no-repeat
</style>