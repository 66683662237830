import axios from 'axios'
import {
    Toast
} from 'vant'
import router from '../router'

const BASE_URL = process.env.VUE_APP_URL

// 创建axios实例
const service = axios.create({
    baseURL: BASE_URL,
})
// 添加request拦截器 
service.interceptors.request.use(
    config => {
        // Toast({
        //     message: '数据加载中...',
        //     type: 'loading',
        //     duration: 1000,
        // })
        return config
    }, error => {
        // setTimeout(() => {
        //     Toast().clear()
        //     Toast({
        //         message: '加载超时'
        //     })
        // }, 3000)
        return Promise.reject(error)
    })
// 添加respone拦截器
service.interceptors.response.use(
    response => {

        let res = {}
        res = response.data
        if (res.code !== 20000) {
            if (res.message) {
                if (res.message.length > 20) {
                    Toast({
                        message: res.message,
                        duration: 2000,
                        forbidClick: true
                    })
                } else {
                    Toast({
                        message: res.message,
                        duration: 2000,
                        forbidClick: true
                    })
                }
            }
            if (res.code == 40001) router.replace('/')
            if (res.code == 40010) router.replace('/')
            if (res.code == 40015) router.replace({
                name: 'changepassword',
                query: {
                    hideBack: true
                }
            })
            if (res.code == 50001) {

                localStorage.removeItem('isOauth')

                console.log('50001', res, localStorage.getItem('isOauth'))

                router.replace('/')
            }
        }
        return res
    },
    error => {
        setTimeout(() => {
            Toast().clear()
            Toast({
                message: '加载超时',
                type: 'loading',
                duration: 20000,
            })
        }, 1000)
        return Promise.reject(error.response)
    }
)

export function get(url, params = {}) {
    params.t = new Date().getTime() //get方法加一个时间参数,解决ie下可能缓存问题.
    return service({
        url: url,
        method: 'get',
        // headers: {
        //     'x-token': localStorage.getItem('token')
        // },
        params
    })
}


//封装post请求
export function post(url, data = {}) {
    //默认配置 
    let sendObject = {
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'x-token': localStorage.getItem('token')
        },
        data: data
    }
    sendObject.data = JSON.stringify(data)
    return service(sendObject)
}

//传图片
export function postImg(url, data = {}) {
    //默认配置 
    let sendObject = {
        url: url,
        method: 'post',
        data: data
    }
    return service(sendObject)
}

//封装put方法 (resfulAPI常用)
export function put(url, data = {}) {
    return service({
        url: url,
        method: 'put',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'x-token': localStorage.getItem('token')
        },
        data: JSON.stringify(data)
    })
}
//删除方法(resfulAPI常用)
export function deletes(url) {
    return service({
        url: url,
        method: 'delete',
        headers: {}
    })
}

//不要忘记export
export {
    service
}

