console.log(location.href, '链接---')

import {
  Dialog, Empty, List, Search, Swipe,
  SwipeItem, Toast
} from 'vant'
import 'vant/lib/index.css'
import Vue from 'vue'
import App from './App.vue'
import fun from './fun.js'
import './registerServiceWorker'
import router from './router'
import store from './store'





Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(Empty);
Vue.use(Search);
Vue.use(Dialog);



Vue.use(Toast);


Vue.use(List)
Vue.prototype.fun = fun
Vue.prototype.toast = Toast


Vue.config.productionTip = false


// let url = window.location.href.split("#")[0];
// if(url.indexOf('from') != -1){
//   url = window.location.href.split("?")[0];
// }
// // url = encodeURIComponent(url);
// window.console.log(url + 'favicon.png');
// console.log('shareImpl');
// ShareImpl({
//   shareTitle: '米链谷川',
//   shareDesc: '让AIXXXXXXX',
//   shareUrl: url,
//   shareImg: url + 'favicon.png',
// });
// axios.interceptors.response.use(response => {
//     console.log(response, '响应拦截器')
//     return response
// }, error => {
//     return Promise.resolve(error.response)
// })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);

})