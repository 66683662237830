import {
    get,
    postImg
} from './http'

// #! 统一添加前缀 http, 避免名称与页面内冲突


const httpIndex = params => get('/mobile/index/index2', params) //车辆列表

const httpDetail = params => get('/mobile/index/detail', params) //车辆详情

const orderSave = params => get('/mobile/order/orderSave', params) //提交订单

const feedbackSave = params => get('/mobile/user/feedbackSave', params) //意见反馈

const userDetail = params => get('/mobile/user/userDetail', params) //个人资料

const upload = params => postImg('/mobile/common/upload', params) //修改头像

const userSave = params => postImg('/mobile/user/userSave', params) //个人资料保存

const kefu = params => get('/mobile/user/kefu', params) //客服

const getOrderList = params => get('/mobile/order/list', params) //获取订单列表

const delOrder = params => get('/mobile/order/del', params) //删除订单

const info = params => get('/mobile/login/info', params) //拿到微信信息

const weixinLogin = params => get('/mobile/login/weixinLogin', params) //微信登录

const logout = params => get('/mobile/login/logout', params) //退出登录

const getSearch = params => get('/mobile/index/getSearch', params) //获取热门搜索

const search = params => get('/mobile/index/search', params) //搜索
const getWx = params => get('/mobile/index/jssdk', params) //微信分享


//const getWxloginCode = params => get('/mobile/login/getWxloginCode', params) //微信登录获取code









// const httpIndex = params => get('/api/mobile/index/index2', params) //车辆列表

// const httpDetail = params => get('/api/mobile/index/detail', params) //车辆详情

// const orderSave = params => get('/api/mobile/order/orderSave', params) //提交订单

// const feedbackSave = params => get('/api/mobile/user/feedbackSave', params) //意见反馈

// const userDetail = params => get('/api/mobile/user/userDetail', params) //个人资料

// const upload = params => postImg('/api/mobile/common/upload', params) //修改头像

// const userSave = params => postImg('/api/mobile/user/userSave', params) //个人资料保存

// const kefu = params => get('/api/mobile/user/kefu', params) //客服

// const getOrderList = params => get('/api/mobile/order/list', params) //获取订单列表

// const delOrder = params => get('/api/mobile/order/del', params) //删除订单

// const info = params => get('/api/mobile/login/info', params) //拿到微信信息

// const weixinLogin = params => get('/api/mobile/login/weixinLogin', params) //微信登录

// const logout = params => get('/api/mobile/login/logout', params) //退出登录

// const getSearch = params => get('/api/mobile/index/getSearch', params) //获取热门搜索

// const search = params => get('/api/mobile/index/search', params) //获取热门搜索





export {
    httpIndex,
    httpDetail,
    orderSave,
    feedbackSave,
    userDetail,
    upload,
    userSave,
    kefu,
    getOrderList,
    delOrder,
    info,
    weixinLogin,
    logout,
    getSearch,
    search,
    getWx
}